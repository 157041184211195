import TagManager from 'react-gtm-module'
import { MouseEvent } from 'react'

import { AnalyticsContext } from '../types/analytics'
import { getClickType } from '../event-utils'
import { Product } from '../types/Product'
import { emptyEcomDataLayer, getAnalyticsProduct } from './utils'
import { ActiveCountryId } from '../constants'
import { getCurrencyCode } from '../locales'

interface ProductCardClickParams {
  context: AnalyticsContext
  product: Product
  event: MouseEvent<Element>
  country: ActiveCountryId
  getLocalizedProductUrl: (product?: Product) => string
}

/**
 * Use GA4 ecommerce product schema to keep GTM variables reusable
 *
 * @param params
 * @returns Data layer object for product card click event
 */

const productCardClickEvent = (params: ProductCardClickParams) => {
  const { context, event, product, country, getLocalizedProductUrl } = params

  const clickType = getClickType(event)
  const analyticsProduct = getAnalyticsProduct({ product, country, getLocalizedProductUrl })
  const currency = getCurrencyCode(country)

  emptyEcomDataLayer() // First empty datalayer
  TagManager.dataLayer({
    dataLayer: {
      event: 'product_card_click',
      product_id: product['product.code'],
      context,
      click_type: clickType,
      ecommerce: {
        currency,
        items: [analyticsProduct],
      },
    },
  })
}

export default productCardClickEvent
