import React, { FC, useEffect, Dispatch, SetStateAction, MouseEvent } from 'react'
import { useRouter } from 'next/router'
import { Button, Message, toaster } from 'rsuite'
import { TypeAttributes } from 'rsuite/esm/@types/common'

import { useAuth } from '../../services/useAuth'
import useSavedProductActions from '../hooks/useSavedProductActions'
import { AnalyticsContext, AnalyticsLocation } from '../../utils/types/analytics'
import { Product } from '../../utils/types/Product'
import HeartIcon from '../Icons/SavedProducts/HeartIcon'
import { ALERT_DURATION } from '../../utils/constants'
import useAdminCustomers from '../../services/useAdminCustomers'
import { isSuperUser } from '../../lib/supplier'

import styles from '../../styles/SaveProductButton.module.less'

interface SaveProductButtonProps {
  product: Product
  analyticsLocation?: AnalyticsLocation | AnalyticsContext
  className?: string
  appearance?: TypeAttributes.Appearance
  setIsRefreshing?: Dispatch<SetStateAction<boolean>>
  onSaveProduct?: (event?: MouseEvent<HTMLElement>) => void
  onUnsaveProduct?: (event?: MouseEvent<HTMLElement>) => void
  iconClassName?: string
  onClick?: (event: MouseEvent<HTMLElement>) => void
}

const SaveProductButton: FC<SaveProductButtonProps> = (props) => {
  const {
    product,
    analyticsLocation,
    className = '',
    appearance = 'ghost',
    setIsRefreshing,
    onSaveProduct = () => {},
    onUnsaveProduct = () => {},
    iconClassName,
    onClick,
  } = props

  const { user } = useAuth()
  const { pathname } = useRouter()

  const {
    selectedCustomer,
  } = useAdminCustomers()

  const {
    handleSaveProductToggle,
    isProductSaved,
    errorMessage,
    isRefreshing,
  } = useSavedProductActions(product, selectedCustomer)

  const isMyProductsPage = pathname === '/my-products'

  useEffect(() => {
    if (errorMessage) {
      toaster.push(
        <Message
          type="error"
          showIcon
          closable
          duration={ALERT_DURATION}
        >
          {errorMessage}
        </Message>,
      )
    }
  }, [errorMessage])

  // Expose isRefreshing to parent component
  useEffect(() => {
    if (setIsRefreshing) {
      setIsRefreshing(isRefreshing)
    }
  }, [isRefreshing])

  const handleOnClick = (event: MouseEvent<HTMLElement>) => {
    onClick?.(event)

    if (isProductSaved) {
      onUnsaveProduct()
    }

    if (!isProductSaved) {
      onSaveProduct()
    }

    handleSaveProductToggle(analyticsLocation)
  }

  const displayProductAsSaved = isProductSaved && (!user?.superuser || isMyProductsPage)

  if (isSuperUser(user)) {
    return null
  }

  return (
    <>
      <Button
        className={`${styles['save-product-button']} ${className}`}
        appearance={appearance}
        onClick={(event) => {
          event.stopPropagation()
          event.preventDefault()
          handleOnClick(event)
        }}
      >
        <HeartIcon
          variant={displayProductAsSaved ? 'primary-dark-filled' : 'dark'}
          className={iconClassName}
        />
      </Button>
    </>
  )
}

export default SaveProductButton
